import React, { useState } from 'react'
import { Scene } from 'react-scrollmagic'

import { DropdownStyledZanzibar } from '../styles/DropdownStyled'
import { BodyM } from '../styles/Typography'

import Minus from './icons/Minus'

/**
 * A dropdown component to be used in the FAQ list.
 * @param {ComponentProps} props
 * @param {String} props.title
 * @param {String} props.triggerId
 * @param {ChildNode} props.children
 * @returns {FunctionComponent}
 */
const DropdownZanzibar = ({ title, triggerId, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const expandBody = (e) => {
    const body = e.target.nextElementSibling

    if (body.style.maxHeight) {
      body.style.maxHeight = null
    } else {
      body.style.maxHeight = body.scrollHeight + 'px'
    }
  }

  const handleClick = (e) => {
    setIsOpen(!isOpen)

    window.requestAnimationFrame(() => expandBody(e))
  }

  return (
    <Scene
      classToggle="reveal"
      triggerElement={triggerId}
      reverse={false}
      offset="-400"
    >
      <DropdownStyledZanzibar>
        <button className="dropdown-head" onClick={(e) => handleClick(e)}>
          <Scene
            classToggle="reveal"
            triggerElement={triggerId}
            reverse={false}
            offset="-400"
          >
            <BodyM className="head-title" weight={600}>
              {title}
            </BodyM>
          </Scene>
          <Scene
            classToggle="reveal"
            triggerElement={triggerId}
            reverse={false}
            offset="-400"
          >
            <div className="head-icon">
              <div className={`icon ${isOpen ? 'rotate' : ''}`}>
                <Minus className={`plus ${isOpen ? 'rotate' : ''}`} />
                <Minus className="minus" />
              </div>
            </div>
          </Scene>
        </button>
        <div className={`dropdown-body ${isOpen ? 'reveal' : ''}`}>
          {children}
        </div>
      </DropdownStyledZanzibar>
    </Scene>
  )
}

export default DropdownZanzibar
