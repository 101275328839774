export const zanzibarLineup = [
  {
    name: 'Dr Banana',
  },
  {
    name: 'Melody',
  },
  {
    name: 'Peach',
  },
  {
    name: 'Sedef Adasï',
  },
  {
    name: 'Alex Ranerro',
  },
  {
    name: 'blsss',
  },
  {
    name: 'Fynn',
  },
  {
    name: 'Fraenz',
  },
  {
    name: 'limbic.sis',
  },
  {
    name: 'Ramez',
  },
  {
    name: 'Ras Moses',
  },
  {
    name: 'Runy',
  },
  {
    name: 'Sumi',
  },
  {
    name: 'SunnySun',
  },
  {
    name: 'vince',
  },
]
