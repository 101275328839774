import React from 'react'

import { theme } from '../../constants/theme'

const LinkArrow = ({ color = theme.textPrimary }) => (
  <svg
    width="38"
    height="34"
    viewBox="0 0 38 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0265 12.1242C25.1836 12.3415 25.2451 12.6102 25.1976 12.8714L23.6759 21.2278C23.5769 21.7717 23.0413 22.1349 22.4796 22.039C21.9179 21.9431 21.5428 21.4244 21.6419 20.8805L22.7306 14.9017L12.9296 21.547C12.4624 21.8638 11.8185 21.7538 11.4913 21.3014C11.1642 20.849 11.2777 20.2255 11.7449 19.9087L21.5459 13.2634L15.3715 12.2091C14.8098 12.1132 14.4348 11.5946 14.5338 11.0507C14.6328 10.5068 15.1685 10.1436 15.7302 10.2395L24.3599 11.713C24.6296 11.759 24.8694 11.9069 25.0265 12.1242Z"
      fill={color}
    />
    <path
      d="M2.656 24.84C2.24 24.84 1.89333 24.7013 1.616 24.424C1.34933 24.1573 1.216 23.816 1.216 23.4L1.216 10.44C1.216 10.024 1.34933 9.68267 1.616 9.416C1.89333 9.13867 2.24 9 2.656 9H5.264V10.408H3.248C2.96 10.408 2.816 10.568 2.816 10.888L2.816 22.952C2.816 23.272 2.96 23.432 3.248 23.432H5.264V24.84H2.656ZM32.5276 24.84V23.432L34.5596 23.432C34.8476 23.432 34.9916 23.272 34.9916 22.952V10.888C34.9916 10.568 34.8476 10.408 34.5596 10.408H32.5276V9H35.1356C35.5623 9 35.909 9.13867 36.1756 9.416C36.453 9.68267 36.5916 10.024 36.5916 10.44V23.4C36.5916 23.816 36.453 24.1573 36.1756 24.424C35.909 24.7013 35.5623 24.84 35.1356 24.84L32.5276 24.84Z"
      fill={color}
    />
  </svg>
)

export default LinkArrow
