import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const ArtistStyled = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;

  &.inner {
    padding-left: 4.4vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-left: 48px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding-left: 30px;
    }
  }

  &.vj {
    margin-top: 5%;
  }

  .name {
    position: relative;
    line-height: 1.4;
    width: fit-content;

    @media (max-width: ${breakpoints.screenSM}) {
      font-size: 10.6vw;
    }

    svg {
      position: absolute;
      top: 15%;
      right: -3vw;
      width: 2.3vw;
      height: auto;

      @media (max-width: ${breakpoints.screenLG}) {
        top: 28px;
        right: -38px;
        width: 30px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        top: 24%;
        right: -8vw;
        width: 6vw;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 110%;
    background-color: ${({ overlayBackground }) => overlayBackground};
    transform-origin: 100% 50%;
    opacity: 0.65;
    will-change: transform;
    pointer-events: none;
  }
`

export { ArtistStyled }
