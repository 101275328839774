import React, { useRef, useEffect } from 'react'

import LinkArrow from './icons/LinkArrow'

import { TitleS } from '../styles/Typography'
import { ArtistStyled } from '../styles/ArtistStyled'

/**
 * An animated artist link to be used in the Lineup list.
 * @param {ComponentProps} props
 * @param {String} props.name
 * @param {String} props.link
 * @param {String} props.color
 * @param {String} props.className
 * @param {String} props.overlayBackground
 * @returns {FunctionComponent}
 */
const Artist = ({ name, link, color, overlayBackground, className }) => {
  const overlayRef = useRef(null)

  const onScroll = () => {
    const overlayOffsetTop = overlayRef.current.getBoundingClientRect().top
    overlayRef.current.style.transform = `translateX(${
      (overlayOffsetTop - window.innerHeight / 2) / -10
    }%)`
  }

  const onSetup = () => {
    const overlayOffsetTop = overlayRef.current.getBoundingClientRect().top
    overlayRef.current.style.transform = `translateX(${
      (overlayOffsetTop - window.innerHeight / 2) / -10
    }%)`
    overlayRef.current.style.transition = `background-color 0.3s linear, transform 0.5s linear`
  }

  useEffect(() => {
    onSetup()

    const timer = setTimeout(() => {
      overlayRef.current.style.transition = `background-color 0.3s linear`
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    onSetup()

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <ArtistStyled overlayBackground={overlayBackground} className={className}>
      {link ? (
        <a href={link} target="_blank">
          <TitleS color={color} className="name">
            {name} <LinkArrow color={color} />
          </TitleS>
        </a>
      ) : (
        <TitleS color={color} className="name">
          {name}
        </TitleS>
      )}
      <span className="overlay" ref={overlayRef} />
    </ArtistStyled>
  )
}

export default Artist
