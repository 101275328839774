import styled, { keyframes } from 'styled-components'

import imgCover from '../assets/img-znz-cover.webp'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ZanzibarStyled = styled.div`
  position: relative;
  overflow-x: hidden;
  height: 100%;

  .cover {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url(${imgCover});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    padding: 32vh 15vw 20vh;

    @media (max-width: ${breakpoints.screenLG}) {
      justify-content: flex-start;
      padding: 35vh 30px 15vh;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      height: calc(var(--vh, 1vh) * 100);
      padding: 35vh 20px 15vh;
    }

    .headline-group {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .text {
      font-size: 8.5vw;
      opacity: 0;
      transform: translateY(12vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      @media (max-width: ${breakpoints.screenLG}) {
        font-size: 10vw;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        font-size: 12vw;
      }
    }

    .date {
      padding-top: 2%;
      padding-left: 0.75vw;
      opacity: 0;
      transform: translateY(12vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      @media (max-width: ${breakpoints.screenLG}) {
        padding-top: 4%;
        transform: translateY(0);
      }

      @media (max-width: ${breakpoints.screenMD}) {
        padding-top: 6%;
      }
    }

    .logos {
      width: 25vw;
      height: auto;

      @media (max-width: ${breakpoints.screenLG}) {
        position: absolute;
        left: 30px;
        bottom: 4%;
        width: 40vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        width: 50vw;
      }

      .logo {
        opacity: 0;
        transition: opacity 1.4s ease;

        &.logo-1 {
          transition-delay: 0.6s;
        }

        &.logo-2 {
          transition-delay: 1s;
        }

        &.logo-3 {
          transition-delay: 1.4s;
        }
      }

      &.reveal {
        .logo-1,
        .logo-2,
        .logo-3 {
          opacity: 1;
        }
      }
    }
  }

  .section-intro {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30vh 15vw 20vh;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 150px 30px 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4vw;
      width: 100%;
      margin-bottom: 30vh;

      @media (max-width: ${breakpoints.screenLG}) {
        gap: 30px;
        margin-bottom: 150px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        flex-direction: column;
        margin-bottom: 100px;

        &.reverse {
          flex-direction: column-reverse;
        }
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    .text-group {
      display: flex;
      flex-direction: column;

      @media (max-width: ${breakpoints.screenSM}) {
        width: 100%;
      }
    }

    .text {
      max-width: 34vw;
      opacity: 0;
      transform: translateY(13vh);
      will-change: transform, opacity;
      transition: transform 1s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      width: 100%;

      @media (max-width: ${breakpoints.screenLG}) {
        font-size: 45px;
        transform: translateY(6vh);
        max-width: 46vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        max-width: 100%;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      &.small {
        font-size: 1.6vw;

        @media (max-width: ${breakpoints.screenLG}) {
          font-size: 24px;
        }
      }
    }

    .link {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      opacity: 0;
      transform: translateY(13vh);
      will-change: transform, opacity;
      transition: transform 1s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: 0.1s;
      margin-top: 8%;
      margin-left: 0.25vw;

      @media (max-width: ${breakpoints.screenLG}) {
        transform: translateY(6vh);
        margin-top: 30px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        margin-top: 20px;
        margin-left: 3px;
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      svg {
        width: 2.2vw;
        height: auto;

        @media (max-width: ${breakpoints.screenLG}) {
          width: auto;
        }
      }
    }

    .img {
      position: relative;
      width: 26vw;

      @media (max-width: ${breakpoints.screenLG}) {
        width: 44vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    .img-text {
      position: absolute;
      bottom: 6%;
      left: 50%;
      transform: translateX(-50%);
      font-style: italic;
      white-space: nowrap;
    }
  }

  .section-collab {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 150px 30px 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }

    .title {
      margin-bottom: 5vh;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 40px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        margin-bottom: 30px;

        br {
          display: none;
        }
      }
    }

    .logos {
      width: 48vw;

      @media (max-width: ${breakpoints.screenLG}) {
        width: 54vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        width: 95%;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .section-lineup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 150px 30px 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }

    .title {
      position: relative;
      line-height: 1;
      margin-bottom: 4%;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 30px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        font-size: 50px;
      }
    }

    .text {
      margin-top: 4%;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-top: 15px;
      }
    }
  }

  .section-programme {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 150px 30px 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }

    .title {
      position: relative;
      line-height: 1;
      margin-bottom: 4%;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 35px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        font-size: 50px;
      }

      :after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.textPrimary};
        height: 0.06em;
        width: 100%;
      }
    }

    .text {
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        line-height: 1.8;
      }

      &.reveal {
        opacity: 1;
      }

      &.mt {
        margin-top: 2%;
      }
    }

    .list {
      padding-top: 3%;
      width: 100%;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        padding-top: 20px;
      }
    }
  }

  .section-gallery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 150px 30px 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }

    .gallery-item {
      position: relative;
      width: 28vw;
      overflow: hidden;
      margin-bottom: 20vh;

      @media (max-width: ${breakpoints.screenLG}) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 50px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        margin-bottom: 30px;
      }

      &.center {
        margin-left: auto;
        margin-right: auto;
      }

      &.large {
        width: 42vw;

        @media (max-width: ${breakpoints.screenLG}) {
          width: 100%;
        }
      }

      &.right {
        margin-left: auto;
      }

      img {
        width: 100%;
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    .img-text {
      position: absolute;
      bottom: 6%;
      left: 50%;
      transform: translateX(-50%);
      font-style: italic;
      white-space: nowrap;
    }
  }

  .section-how-it-looks {
    display: flex;
    flex-direction: column;
    gap: 20vh;
    padding: 20vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      gap: 40px;
      padding: 200px 30px 50px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 200px 20px 50px;
    }

    .content {
      display: flex;
      align-items: center;
      gap: 3vw;

      &.reverse {
        flex-direction: row-reverse;
        text-align: right;

        @media (max-width: ${breakpoints.screenLG}) {
          flex-direction: column;
        }

        .text-wrapper {
          align-items: flex-end;

          @media (max-width: ${breakpoints.screenLG}) {
            align-items: flex-start;
          }
        }
      }

      @media (max-width: ${breakpoints.screenLG}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .text {
      line-height: 0.9;
      opacity: 0;
      transform: translateY(10vh);
      will-change: transform, opacity;
      transition: transform 1.5s cubic-bezier(0.22, 0.94, 0.44, 1),
        opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        line-height: 1;
        opacity: 1;
        transform: translateY(0);
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }

      :nth-child(2) {
        transition-delay: 0.2s;
      }

      :nth-child(3) {
        transition-delay: 0.4s;
      }

      :after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.02em;
        background-color: ${({ theme }) => theme.textPrimary};
        height: 0.06em;
        width: 100%;
      }
    }

    .image {
      width: 40vw;
      opacity: 0.8;
      will-change: opacity;
      transition: opacity 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      overflow: hidden;

      @media (max-width: ${breakpoints.screenLG}) {
        opacity: 0.05;
        width: 100%;
        margin-left: 0;
      }

      &.reveal {
        opacity: 0.8;
      }

      img {
        width: 100%;
      }
    }
  }

  .section-more-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 150px 30px 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 150px 20px 0;
    }

    .text {
      position: relative;
      line-height: 1;
      margin-bottom: 2.5%;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 30px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        font-size: 50px;
      }
    }

    .list {
      padding-top: 3%;
      width: 100%;
      z-index: 1;

      @media (max-width: ${breakpoints.screenLG}) {
        padding-top: 20px;
      }
    }
  }
`

export { ZanzibarStyled }
